import { gql } from '@apollo/client';

export default class DealsSettingsQuery {
    static DEALS_SETTINGS_GENERAL = gql`
        query getDealSettingsGeneral($lotName: String!){
            getDealSettingsGeneral(lotName: $lotName) {
                internetPrice
                stickerPrice
                minimumPrice
                docStampPercentage
                leaveDealsInQuote
                defaultTerm
                defaultInterestRate
                defaultPaymentFrequency
                salesTaxMaximumEnabled
                salesTaxMaximumValue
                allowTransferWithoutDownpayment
                defaultFinanceCalculation
                defaultFinalOddPayment
            }
        }
    `;

    static DEALS_SETTINGS_INTEGRATION = gql`
        query getDealSettingsIntegration($lotName: String!){
            getDealSettingsIntegration(lotName: $lotName) {
                secureCloseEnabled
            }
        }
    `;

    static DEALS_SETTINGS_USER_TAB = gql`
        query getDealSettingsUserTab($lotName: String!){
            getDealSettingsUserTab(lotName: $lotName) {
                dealUserTabName1
                dealUserTabName2
                dealUserTabName3
                dealUserTabName4
                dealUserTabName5
                dealUserTabURL1
                dealUserTabURL2
                dealUserTabURL3
                dealUserTabURL4
                dealUserTabURL5
            }
        }
    `;

    static DEALS_SETTINGS_MISCELLANEA = gql`
        query getDealSettingsMiscellanea($lotName: String!){
            getDealSettingsMiscellanea(lotName: $lotName) {
                discountOnFrontEnd
                buyersOrderDisclosure
                doNotCollectTaxOnOutOfStateDeals
            }
        }
    `;

    static AUTOZOOM_LOT_CONFIG = gql`
        query getDealSettingsAutoZoomLotConfig($lotName: String!){
            getDealSettingsAutoZoomLotConfig(lotName: $lotName) {
                approverId
                locationId
                salespersonId
            }
        }
    `;

    static AUTOZOOM_CATALOGS_LIST = gql`
        {
            getDealSettingsAutoZoomCatalogs {
                autoZoomApprovers{
                    id
                    name
                }
                autoZoomLocations{
                    id
                    name
                }
                autoZoomSalesPersons{
                    id
                    name
                }
            }
        }
    `;

    static GET_DEAL_TRANSFER_LOTS_MAPPINGS = gql`
        query getDealTransferLotsMappings{
            getDealTransferLotsMappings
        }
    `;

    static PULL_DEAL_FRONTEND_BACKEND_FORMULA = gql`
        {
            pullDealFrontendBackendFormula {
                frontendFormula
                backendFormula
            }
        }
    `;

    static PULL_PROFIT_FORMULA_FIELDS = gql`
        {
            pullProfitFormulaFields
        }
    `;
}
