import React, {
    useEffect,
    useContext,
    useReducer,
} from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles, Button, Grid,
    fade, TextField,
} from '@material-ui/core';
import KeyStore from 'utils/KeyStore';
import update from 'immutability-helper';
import Select from 'components/widgets/Select';
import { isEmpty } from 'lodash';

import { FetchPolicy, ALL_LOTS } from 'utils/enum/Core';
import LotsCategory, { LotDefaultskey } from 'utils/enum/LotsCategory';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import UserContext from 'components/context/UserContext';
import LotQuery from 'services/graphQL/query/LotQuery';
import LotMutation from 'services/graphQL/mutate/LotMutation';
import DealsSettingsQuery from 'services/graphQL/query/DealsSettingsQuery';
import DealsSettingsMutate from 'services/graphQL/mutate/DealsSettingsMutate';
import { Col, Form } from 'react-bootstrap';
import ArrayUtils from 'lib/ArrayUtils';
import ModalUtils from 'utils/ModalUtils';
import ButtonStyles from 'styles/theme/Button';
import If from 'components/widgets/conditional/If';
import InputNumber from 'components/widgets/InputNumber';
import Jodit from 'components/widgets/editor/JoditEditor';

const joditConfiguration = {
    readonly: false,
    disablePlugins: 'paste',
    height: 300,
    allowResizeY: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showPlaceholder: true,
    showXPathInStatusbar: false,
    toolbarButtonSize: 'small',
    buttons: [
        'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'ul', 'ol',
        'indent', 'outdent', 'left', 'font', 'fontsize', 'paragraph', 'classSpan', 'brush',
        'image', 'link', 'source',
    ],
};

const ACTION_TYPES = {
    ON_CHANGE_LOT: 'onChangeLot',
    SET_RECORD: 'setRecord',
    SET_RECORD_CHANGED: 'setRecordChanged',
    ON_CHANGE_DISCLOSURE: 'onChangeDisclosure',
    ON_CHANGE_GLOBAL_DEALER_ID: 'OnChangeGlobalDealerId',
};

const reducer = (state, action = {}) => {
    switch (action.type) {
    case ACTION_TYPES.SET_RECORD:
        return {
            ...state,
            record: action.value,
        };
    case ACTION_TYPES.SET_RECORD_CHANGED:
        return {
            ...state,
            record: action.value,
        };
    case ACTION_TYPES.ON_CHANGE_LOT:
        return update(state, {
            lotId: { $set: action.payload },
        });
    case ACTION_TYPES.ON_CHANGE_DISCLOSURE:
        return update(state, {
            disclosure: { $set: action.payload },
        });
    case ACTION_TYPES.ON_CHANGE_GLOBAL_DEALER_ID:
        return update(state, {
            globalDealerId: { $set: action.payload },
        });
    default:
        return state;
    }
};

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme, fade));
const useStyles = makeStyles((theme) => ({
    box: {
        marginTop: '10px',
        paddingTop: '10px',
    },
    labels: {
        fontSize: '0.875rem',
        fontWeight: 'bold',
    },
    subLabel: {
        fontSize: '0.875rem',
    },
    boxContainer: {
        flexDirection: 'column',
        background: theme.palette.background.default,
    },
    paddingLeft25: {
        paddingLeft: '25px',
    },
    input: {
        fontSize: '14px',
    },
    customTabsSave: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    alignCheckBox: {
        marginBottom: '10px',
        marginLeft: '10px',
    },
    labelCheck: {
        marginBottom: 0,
        paddingTop: '10px',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    dealerId: {
        '& > div': {
            display: 'flex',
            '& > input': {
                width: '150px',
                marginRight: '5px',
            },
        },
    },
    disclosureWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    editor: {
        '& > div:nth-child(2)': {
            border: `solid 1px ${theme.palette.border.mercury}`,
        },
    },
}));

const DealsMiscellaneaSettings = ({ writable }) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const { userInformation = {} } = useContext(UserContext);
    const keyStore = new KeyStore();
    const [state, dispatch] = useReducer(reducer, {
        lotId: keyStore.getSelectedLot().lotId,
        record: {
            discountOnFrontEnd: false,
            buyersOrderDisclosure: '',
            doNotCollectTaxOnOutOfStateDeals: false,
        },
        disclosure: null,
        globalDealerId: null,
    });

    const availableLots = (userInformation.lots || []).map((item) => ({ value: item.lotId, label: item.lotName }));

    const {
        record,
        lotId,
        disclosure,
        globalDealerId,
    } = state;

    const {
        data: menuSettingsData,
        loading: loadingMenuSettings,
        error: errorLoadingMenuSettings,
    } = useQuery(LotQuery.GET_SETTINGS, {
        variables: {
            category: LotsCategory.MENU,
            key: [LotDefaultskey.MENU_DISCLOSURE, LotDefaultskey.FI_GLOBAL_DEALER_ID],
            lotName: ALL_LOTS,
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const [getSettings, { loading }] = useLazyQuery(DealsSettingsQuery.DEALS_SETTINGS_MISCELLANEA, {
        onCompleted: (response) => {
            if (response) {
                dispatch({
                    type: ACTION_TYPES.SET_RECORD,
                    value: response.getDealSettingsMiscellanea || {},
                });
            }
        },
        onError: (error) => {
            ModalUtils.errorMessage([error]);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    useEffect(() => {
        if (errorLoadingMenuSettings) {
            ModalUtils.errorMessage(errorLoadingMenuSettings?.graphQLErrors);
            return;
        }

        if (!loadingMenuSettings) {
            const data = menuSettingsData?.getSettings;
            if (ArrayUtils.isNotEmpty(data)) {
                const disclosureValue = data.find((x) => x.key === LotDefaultskey.MENU_DISCLOSURE)?.value;
                const globalId = data.find((x) => x.key === LotDefaultskey.FI_GLOBAL_DEALER_ID)?.value;

                dispatch({
                    type: ACTION_TYPES.ON_CHANGE_DISCLOSURE,
                    payload: disclosureValue,
                });

                if (globalId) {
                    dispatch({
                        type: ACTION_TYPES.ON_CHANGE_GLOBAL_DEALER_ID,
                        payload: globalId,
                    });
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingMenuSettings, errorLoadingMenuSettings]);

    useEffect(() => {
        let { lotName } = keyStore.getSelectedLot();
        if (!isEmpty(availableLots)) {
            const currentLot = availableLots.find((obj) => obj.value === state.lotId);
            if (!isEmpty(currentLot)) {
                lotName = currentLot.label;
            }
        }

        if (lotName) {
            getSettings({
                variables: {
                    lotName,
                },
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.lotId]);

    const [saveMiscellaneaSettings, { loading: isSavingSettings }] = useMutation(DealsSettingsMutate.SAVE_DEALS_SETTINGS_MISCELLANEA, {
        onCompleted: (response) => {
            if (response) {
                ModalUtils.successMessage(null, 'Deal Settings saved successfully');
                return;
            }

            ModalUtils.errorMessage(null, 'There was an error trying to save the Deal Settings');
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const saveSettings = () => {
        const currentLot = availableLots.find((obj) => obj.value === lotId);

        if (currentLot) {
            saveMiscellaneaSettings({
                variables: {
                    input: record,
                    lotName: currentLot.label,
                },
            });
        }
    };

    const onChange = async (field, value) => {
        if (field === 'disclosure') {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE_DISCLOSURE,
                payload: value,
            });

            return;
        }

        if (field === 'globalDealerId') {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE_GLOBAL_DEALER_ID,
                payload: value,
            });

            return;
        }

        if (value === record[field]) return;
        const currentRecord = { ...record };
        currentRecord[field] = value;

        dispatch({
            type: ACTION_TYPES.SET_RECORD_CHANGED,
            value: currentRecord,
        });
    };

    const onChangeLot = (field, value) => {
        dispatch({
            type: ACTION_TYPES.ON_CHANGE_LOT,
            payload: value,
        });
    };

    const [saveMenuSetting, { loading: isSavingMenuSetting }] = useMutation(LotMutation.SAVE_LOT_DEFAULTS, {
        onCompleted: (response) => {
            if (response) {
                ModalUtils.successMessage(null, 'Menu setting saved successfully');
                return;
            }

            ModalUtils.errorMessage(null, 'There was an error trying to save the menu setting');
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage([errorMessage]);
        },
    });

    const onSaveGlobalDealerId = () => {
        saveMenuSetting({
            variables: {
                category: LotsCategory.MENU,
                key: LotDefaultskey.FI_GLOBAL_DEALER_ID,
                value: String(globalDealerId),
                lotName: ALL_LOTS,
                critical: false,
            },
        });
    };

    const onSaveDisclosure = () => {
        saveMenuSetting({
            variables: {
                category: LotsCategory.MENU,
                key: LotDefaultskey.MENU_DISCLOSURE,
                value: disclosure,
                lotName: ALL_LOTS,
                critical: false,
            },
        });
    };

    const shouldDisableButton = () => ((loading || isSavingSettings));
    const saveButtonText = () => ((isSavingSettings) ? 'Saving' : 'Save');

    return (
        <>
            <Grid container className={classes.box}>
                <Grid item md={6} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item md={10} xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label>Lot</Form.Label>
                                <Select
                                    size="sm"
                                    name="lotId"
                                    value={state.lotId}
                                    placeholder="Select"
                                    options={availableLots}
                                    onChange={onChangeLot}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Form.Group as={Col} className={classes.flexCenter}>
                                <Form.Check
                                    onChange={(e) => onChange('discountOnFrontEnd', e.target.checked)}
                                    checked={record.discountOnFrontEnd}
                                    className={classes.alignCheckBox}
                                    inlines
                                    label="Discount On FrontEnd Profit"
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Form.Group as={Col} className={classes.flexCenter}>
                                <Form.Check
                                    onChange={(e) => onChange('doNotCollectTaxOnOutOfStateDeals', e.target.checked)}
                                    checked={record.doNotCollectTaxOnOutOfStateDeals}
                                    className={classes.alignCheckBox}
                                    inline
                                    label="Do Not Collect Tax On Out Of State Deals"
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item md={10} xs={12}>
                            <Form.Group as={Col}>
                                <Form.Label>Buyer&apos;s Order Disclosure</Form.Label>
                                <TextField
                                    multiline
                                    fullWidth
                                    rows={6}
                                    variant="outlined"
                                    value={record.buyersOrderDisclosure}
                                    onChange={(e) => onChange('buyersOrderDisclosure', e.target.value)}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <If condition={writable}>
                        <Grid container>
                            <Grid item md={10} xs={12}>
                                <Form.Group className={classes.customTabsSave} as={Col}>
                                    <Button
                                        className={classes.containedSecondaryInfo}
                                        size="small"
                                        disabled={shouldDisableButton()}
                                        onClick={saveSettings}
                                    >
                                        {saveButtonText()}
                                    </Button>
                                </Form.Group>
                            </Grid>
                        </Grid>
                    </If>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Grid container spacing={1}>
                        <Form.Group as={Col} className={classes.dealerId}>
                            <Form.Label>F&I Express Global Dealer ID</Form.Label>
                            <div>
                                <InputNumber
                                    placeholder={0}
                                    removeDecimalScale
                                    value={globalDealerId}
                                    onChange={(value) => onChange('globalDealerId', value)}
                                />
                                <If condition={writable}>
                                    <Button
                                        disabled={!globalDealerId || isSavingMenuSetting}
                                        className={classes.containedSecondaryInfo}
                                        size="small"
                                        onClick={onSaveGlobalDealerId}
                                    >
                                        Save
                                    </Button>
                                </If>
                            </div>
                        </Form.Group>
                    </Grid>
                    <Grid container spacing={1} className={classes.disclosureWrapper}>
                        <Form.Group as={Col} className={classes.editor}>
                            <Form.Label>Menu Disclosure</Form.Label>
                            <Jodit
                                config={joditConfiguration}
                                value={disclosure || ''}
                                onChange={(newContent) => onChange('disclosure', newContent)}
                            />
                        </Form.Group>
                        <If condition={writable}>
                            <Form.Group className={classes.customTabsSave} as={Col}>
                                <Button
                                    disabled={!disclosure || isSavingMenuSetting}
                                    className={classes.containedSecondaryInfo}
                                    size="small"
                                    onClick={onSaveDisclosure}
                                >
                                    Save
                                </Button>
                            </Form.Group>
                        </If>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

DealsMiscellaneaSettings.propTypes = {
    writable: PropTypes.bool.isRequired,
};

export default DealsMiscellaneaSettings;
