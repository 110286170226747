import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Grid,
    makeStyles,
    FormLabel,
    TextField,
    Button,
    Dialog,
    DialogContent,
} from '@material-ui/core';
import TableUtils from 'utils/TableUtils';
import { useMutation } from '@apollo/client';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import Table from 'components/widgets/Table';
import StringUtils from 'lib/StringUtils';
import DateUtils from 'lib/DateUtils';
import DataType from 'utils/enum/DataType';
import DatePicker from 'react-datepicker';
import ModalUtils from 'utils/ModalUtils';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ButtonStyles from 'styles/theme/Button';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import DialogAppBar from 'components/widgets/modal/DialogAppBar';
import CalendarContainer from 'components/widgets/form/CalendarContainer';
import DisplayInput from 'components/modules/inventory/create/panels/DisplayInputNumber';
import DisplaySelect from 'components/modules/inventory/create/panels/DisplaySelect';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => ({
    root: {
        padding: '5px 10px',
        '& > div': {
            alignItems: 'center',
            marginBottom: 5,
            paddingRight: 5,
            '& > div:first-child': {
                alignItems: 'center',
                paddingRight: 5,
            },
        },
    },
    columnsRoot: {
        flexDirection: 'column',
        display: 'flex',
        flex: 'initial',
        flexWrap: 'nowrap',
        '& > div': {
            maxWidth: '100%',
        },
    },
    datePicker: {
        '& > div:first-child': {
            width: '100%',
        },
        '& input': {
            maxHeight: 31,
            fontSize: '14px',
        },
    },
    textFieldSmall: {
        '& input': {
            padding: 7,
        },
    },
    AppBar: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.background.sanMarino,
        '& h4': {
            color: theme.palette.text.white,
        },
    },
    newContainer: {
        display: 'flex',
        width: '80px',
        marginBottom: '15px',
    },
    tableHeader: {
        display: 'flex',
        justifyContent: 'center',
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > button:nth-child(1)': {
            marginRight: '2px',
        },
        '& > button': {
            minWidth: '32px',
            '& .MuiButton-startIcon': {
                marginRight: '0px',
            },
        },
    },
}));

const INIT_DATA = {
    customTableKey: TableUtils.generateUUID(),
    creatingNewOption: false,
    isEditDialogOpen: false,
    isDeletePromptOpen: false,
    markedOptionForRemoval: undefined,
    editModeEnabled: false,
    selectedCustomField: undefined,
    selectedCustomFieldOption: undefined,
};

const CustomFieldsEditPanel = ({
    data,
    onChange,
    updateCustomFieldOptions,
    writePermission,
    displayInColumns,
}) => {
    const classes = { ...useStyles(), ...buttonStyles() };
    const [state, setState] = useState(INIT_DATA);
    const {
        isEditDialogOpen,
        isDeletePromptOpen,
        markedOptionForRemoval,
        selectedCustomField,
        selectedCustomFieldOption,
        editModeEnabled,
        customTableKey,
        creatingNewOption,
    } = state;

    const toggleEditMode = (option, updatedOptions, createdId) => {
        const stateClone = { ...state };
        stateClone.editModeEnabled = !stateClone.editModeEnabled;
        stateClone.selectedCustomFieldOption = option;
        stateClone.customTableKey = TableUtils.generateUUID();

        if (updatedOptions) {
            stateClone.selectedCustomField = {
                ...selectedCustomField,
                options: updatedOptions,
            };

            stateClone.creatingNewOption = true;
        } else if (stateClone.creatingNewOption) {
            if (!createdId) {
                const currentOptions = [...selectedCustomField.options];
                currentOptions.shift(0);

                stateClone.selectedCustomField = {
                    ...selectedCustomField,
                    options: currentOptions,
                };
            } else {
                const currentOptions = [...selectedCustomField.options];
                currentOptions[0].entityMetadataOptionId = createdId;
                stateClone.selectedCustomField = {
                    ...selectedCustomField,
                    options: currentOptions,
                };
            }

            stateClone.creatingNewOption = false;
        }

        setState(stateClone);
    };

    const [
        deleteMetadataOption,
    ] = useMutation(InventoryMutation.DELETE_METADATA_OPTION, {
        onCompleted: (response) => {
            if (response) {
                const stateClone = { ...state };

                const filteredOptions = selectedCustomField
                    .options
                    .filter(
                        (option) => option.entityMetadataOptionId !== markedOptionForRemoval.entityMetadataOptionId,
                    );
                stateClone.customTableKey = TableUtils.generateUUID();
                stateClone.isDeletePromptOpen = !isDeletePromptOpen;
                stateClone.markedOptionForRemoval = undefined;
                stateClone.selectedCustomField = {
                    ...selectedCustomField,
                    options: filteredOptions,
                };

                setState(stateClone);
                ModalUtils.successMessage(null, 'Option removed successfully.');
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [
        updateMetadataOption,
    ] = useMutation(InventoryMutation.UPDATE_METADATA_OPTION, {
        onCompleted: (response) => {
            if (response) {
                toggleEditMode();
                ModalUtils.successMessage(null, 'Option updated successfully.');
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [
        createMetadataOption,
    ] = useMutation(InventoryMutation.CREATE_METADATA_OPTION, {
        onCompleted: (response) => {
            if (response) {
                const id = response?.createMetadataOption?.entityMetadataOptionId;
                toggleEditMode(undefined, undefined, id);
                ModalUtils.successMessage(null, 'Option created successfully.');
            }
        },
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const toggleEditModal = (item) => {
        const stateClone = { ...state };
        if (!item) {
            const clone = { ...selectedCustomField };

            if (creatingNewOption) {
                const currentOptions = [...clone.options];
                currentOptions.shift(0);

                clone.options = currentOptions;
            }

            updateCustomFieldOptions(clone);
            setState(INIT_DATA);
            return;
        }

        stateClone.selectedCustomField = item;
        stateClone.isEditDialogOpen = !stateClone.isEditDialogOpen;
        setState(stateClone);
    };

    const toggleDeletePrompt = (option) => {
        const stateClone = { ...state };
        stateClone.isDeletePromptOpen = !stateClone.isDeletePromptOpen;
        stateClone.markedOptionForRemoval = option;

        setState(stateClone);
    };

    const deteleOption = () => {
        deleteMetadataOption({
            variables: {
                entityMetadataOptionId: markedOptionForRemoval.entityMetadataOptionId,
            },
        });
    };

    const onLabelChange = (id, value) => {
        const stateClone = { ...state };
        const updatedOptions = selectedCustomField
            .options
            .map(
                (option) => {
                    const clone = { ...option };

                    if (clone.entityMetadataOptionId === id) clone.option = value;
                    return clone;
                },
            );

        stateClone.selectedCustomField = {
            ...selectedCustomField,
            options: updatedOptions,
        };
        setState(stateClone);
    };

    const saveRow = (option) => {
        if (creatingNewOption) {
            createMetadataOption({
                variables: {
                    entityMetadataId: selectedCustomField.entityMetadataId,
                    option: option.option,
                },
            });
            return;
        }

        updateMetadataOption({
            variables: {
                entityMetadataOptionId: option.entityMetadataOptionId,
                option: option.option,
            },
        });
    };

    const createItem = () => {
        const item = {
            entityMetadataOptionId: TableUtils.generateUUID(),
            option: '',
        };

        const updatedOptions = [item, ...selectedCustomField.options];
        toggleEditMode(item, updatedOptions);
    };

    const renderComboField = (item) => {
        const sortedOptions = item.options.map((option) => ({
            label: option.option,
            value: option.option,
        }));

        sortedOptions.sort((a, b) => a.label.localeCompare(b.label));
        const options = [
            {
                label: 'None',
                value: '',
            },
            ...sortedOptions,
            {
                label: (
                    <Button
                        className={classes.containedInfo}
                        size="medium"
                        fullWidth
                        onClick={() => toggleEditModal(item)}
                    >
                        Add / Edit
                    </Button>
                ),
                value: '-1',
                isDisabled: true,
            },
        ];

        return (
            <DisplaySelect
                key={item.entityMetadataId}
                label={item.label}
                value={item.value}
                onChange={(value) => onChange(item.entityMetadataId, value)}
                options={options}
            />
        );
    };

    const renderDateField = (item) => (
        <Grid
            key={item.entityMetadataId}
            container
            item
            xs={12}
            sm={6}
        >
            <Grid item xs={12} sm={5}>
                <FormLabel>{`${item.label}:`}</FormLabel>
            </Grid>
            <Grid item xs={12} sm={7} className={classes.datePicker}>
                <DatePicker
                    size="small"
                    className="form-control"
                    onChange={(date) => onChange(
                        item.entityMetadataId,
                        StringUtils.isEmpty(date) ? DateUtils.format(new Date()) : DateUtils.format(date),
                    )}
                    selected={DateUtils.isValid(item.value) ? DateUtils.toLocal(item.value).toDate() : ''}
                    popperPlacement="top-end"
                    popperContainer={CalendarContainer}
                />
            </Grid>
        </Grid>
    );

    const renderMoneyField = (item) => {
        const money = Number(item.value);

        return (
            <DisplayInput
                key={item.entityMetadataId}
                label={item.label}
                value={money}
                onChange={(value) => onChange(item.entityMetadataId, value.toString())}
            />
        );
    };

    const renderStringField = (item) => (
        <Grid
            key={item.entityMetadataId}
            container
            item
            xs={12}
            sm={6}
        >
            <Grid item xs={12} sm={5}>
                <FormLabel>{`${item.label}:`}</FormLabel>
            </Grid>
            <Grid item xs={12} sm={7}>
                <TextField
                    className={classes.textFieldSmall}
                    value={!StringUtils.isEmpty(item.value) ? item.value : ''}
                    onChange={({ target: { value } }) => onChange(item.entityMetadataId, value)}
                    variant="outlined"
                    size="small"
                />
            </Grid>
        </Grid>
    );

    const renderEditField = (item) => {
        const type = item.type?.toLowerCase();

        switch (type) {
        case DataType.COMBO:
            return renderComboField(item);
        case DataType.DATE:
            return renderDateField(item);
        case DataType.MONEY:
            return renderMoneyField(item);
        default:
            return renderStringField(item);
        }
    };

    const getEditionTableColumns = () => [
        {
            Header: 'Label Name',
            id: 'description',
            accessor: (record) => record,
            className: classes.tableHeader,
            Cell: ({ value }) => {
                const record = value || {};
                const { option, entityMetadataOptionId } = record;

                if (
                    editModeEnabled
                    && entityMetadataOptionId === selectedCustomFieldOption.entityMetadataOptionId
                ) {
                    return (
                        <TextField
                            className={classes.textFieldSmall}
                            autoFocus
                            value={option}
                            onChange={({ target }) => onLabelChange(entityMetadataOptionId, target.value)}
                            variant="outlined"
                            size="small"
                        />
                    );
                }

                return option;
            },
        },
        {
            Header: 'Actions',
            id: 'action',
            accessor: (record) => record,
            sortable: false,
            headerClassName: 'no-sort',
            minWidth: 100,
            maxWidth: 100,
            // eslint-disable-next-line react/prop-types
            Cell: ({ value }) => {
                const record = value || {};
                const { entityMetadataOptionId, option } = record;

                if (
                    editModeEnabled
                    && entityMetadataOptionId === selectedCustomFieldOption.entityMetadataOptionId
                ) {
                    return (
                        <div className={classes.actionsContainer}>
                            <Button
                                className={classes.containedInfo}
                                size="small"
                                startIcon={<CheckOutlinedIcon />}
                                disabled={StringUtils.isEmpty(option)}
                                onClick={() => saveRow(record)}
                            />
                            <Button
                                className={classes.containedError}
                                size="small"
                                startIcon={<CloseOutlinedIcon />}
                                onClick={() => toggleEditMode()}
                            />
                        </div>
                    );
                }

                return (
                    <div className={classes.actionsContainer}>
                        <Button
                            className={classes.containedInfo}
                            size="small"
                            disabled={creatingNewOption || editModeEnabled}
                            startIcon={<EditOutlinedIcon />}
                            onClick={() => toggleEditMode(record)}
                        />
                        <Button
                            className={classes.containedError}
                            size="small"
                            disabled={creatingNewOption || editModeEnabled}
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            onClick={() => toggleDeletePrompt(record)}
                        />
                    </div>
                );
            },
        },
    ];

    if (!writePermission) {
        return null;
    }

    return (
        <>
            <Grid
                container
                orientation="column"
                className={clsx(classes.root, displayInColumns ? classes.columnsRoot : '')}
            >
                {data.map((item) => renderEditField(item))}
            </Grid>
            <Dialog
                open={isEditDialogOpen}
                fullWidth
                maxWidth="md"
                disableBackdropClick
                disableEscapeKeyDown
                scroll="paper"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <DialogAppBar
                    appBarClassName={classes.AppBar}
                    title={`Add / Edit Custom Field: ${selectedCustomField?.label}`}
                    onClose={() => toggleEditModal()}
                    toolbarSize="md"
                />
                <DialogContent>
                    <div className={classes.newContainer}>
                        <Button
                            className={classes.containedInfo}
                            size="medium"
                            fullWidth
                            disabled={creatingNewOption || editModeEnabled}
                            onClick={createItem}
                        >
                            New
                        </Button>
                    </div>
                    <Table
                        key={customTableKey}
                        cursor="default"
                        data={selectedCustomField?.options}
                        columns={getEditionTableColumns()}
                        getTrProps={(_, rowInfo) => {
                            const record = rowInfo.original;

                            return {
                                onDoubleClick: () => toggleEditMode(record),
                            };
                        }}
                    />
                </DialogContent>
            </Dialog>
            <ConfirmDialog
                title="Attention!"
                description="Do you want to remove this option?"
                open={isDeletePromptOpen}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={toggleDeletePrompt}
                onClickSecondary={toggleDeletePrompt}
                onClickPrimary={deteleOption}
            />
        </>
    );
};

CustomFieldsEditPanel.propTypes = {
    data: PropTypes.array,
    onChange: PropTypes.func,
    updateCustomFieldOptions: PropTypes.func,
    writePermission: PropTypes.bool,
    displayInColumns: PropTypes.bool,
};

CustomFieldsEditPanel.defaultProps = {
    data: [],
    onChange: () => null,
    updateCustomFieldOptions: () => null,
    writePermission: false,
    displayInColumns: false,
};

export default CustomFieldsEditPanel;
