/* eslint-disable no-param-reassign */
import React, { useEffect, useReducer, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

import produce from 'immer';
import clsx from 'clsx';
import { filter, isEmpty } from 'lodash';

import useVinValidation from 'components/modules/inventory/hooks/useVinValidation';
import {
    useLazyQuery,
    useQuery,
    useMutation,
    useApolloClient,
} from '@apollo/client';
import InventoryMutation from 'services/graphQL/mutate/InventoryMutation';
import InventoryQuery from 'services/graphQL/query/InventoryQuery';
import LotQuery from 'services/graphQL/query/LotQuery';
import SettingsQuery from 'services/graphQL/query/SettingsQuery';
import CatalogQuery from 'services/graphQL/query/CatalogQuery';
import VendorQuery from 'services/graphQL/query/VendorQuery';
import InventoryMap from 'services/mapData/InventoryMap';
import MapInventoryData from 'services/mapData/MapInventoryData';
import usePurchasingActions from 'components/modules/inventory/hooks/usePurchasingActions';

import {
    makeStyles,
} from '@material-ui/core';
import VinDecoder from 'components/modules/inventory/create/createForm/VinDecoder';
import Toolbar from 'components/modules/inventory/create/createForm/Toolbar';
import VehicleForm from 'components/modules/inventory/create/createForm/VehicleForm';
import UserContext from 'components/context/UserContext';

import StringUtils from 'lib/StringUtils';
import DateUtils from 'lib/DateUtils';
import ModalUtils from 'utils/ModalUtils';
import SettingsHelper from 'utils/SettingsHelper';
import LotsCategory, { LotDefaultskey } from 'utils/enum/LotsCategory';
import Permission from 'utils/enum/Permissions';
import KeyStore from 'utils/KeyStore';
import { VendorType } from 'utils/enum/Vendors';
import {
    FetchPolicy,
    ALL_LOTS,
    DataSort,
} from 'utils/enum/Core';
import { modules } from 'utils/enum/modules';
import InventoryHelper from 'utils/InventoryHelper';
import {
    Transmissions, InventoryColor,
    VehicleInfoProviders, InventoryCategory,
    TitleStatus,
} from 'utils/enum/InventoryEnum';

const useStyles = makeStyles((theme) => ({
    main: {
        height: '100%',
        width: '100%',
        background: theme.palette.background.default,
        padding: 10,
        [theme.breakpoints.down(780)]: {
            position: 'absolute',
            zIndex: 10,
        },
    },
}));

const cleanValue = TitleStatus[1];
const initState = {
    vinNumber: '',
    enterManually: false,
    purchasedCreationLoading: false,
    purchasedFromList: [],
    purchasingAgentList: [],
    data: {
        thumbnail: '',
        alternativeThumbnail: '',
        year: '',
        make: '',
        model: '',
        drivetrain: '',
        trim: '',
        trimList: [],
        decodedStyle: '',
        style: '',
        engine: '',
        engineList: [],
        vin: '',
        transmission: Transmissions.AUTOMATIC,
        genericExteriorColor: '',
        extColor: '',
        intColor: '',
        mpgCity: 0,
        mpgHighway: 0,
        lotName: '',
        miles: 0,
        isTMU: false,
        isExempt: false,
        isEML: false,
        title: false,
        titleNumber: '',
        titleStatus: cleanValue,
        isWholesale: false,
        spareKey: '',
        tag: '',
        tagExpires: '',
        userTabURL1: '',
        userTabURL2: '',
        purchasingInfo: {
            purchasedFrom: '',
            purchasedAgent: '',
            purchasedDate: DateUtils.format(new Date()),
            cr: 0,
            isFrame: false,
            purchasedPrice: 0,
            packOverride: false,
            pack: 0,
            repairs: 0,
            cost: 0,
            carFax: '',
            autoCheck: '',
            maximumReconCost: null,
        },
        pricingInfo: {
            isSlashedPrice: false,
            slashedPrice: 0,
            stickerPrice: 0,
            internetPrice: 0,
            minimumPrice: 0,
        },
        advertisingInfo: {
            isReducePrice: false,
            reducePrice: '',
            every: '',
            removeAd: 'Immediately',
            isPlaceOnline: true,
        },
        floorPlanInfo: {
            isFloorPlanned: false,
            floorPlannedDate: null,
            flooredBy: 0,
            isBorrowedAmountOverride: false,
            borrowedAmount: 0,
        },
        lienHolderInfo: {},
    },
    colorsData: [],
    styleList: [
        'Unknown',
        'Convertible',
        'Coupe',
        'Hatchback',
        'Minivan',
        'Sedan',
        'SUV',
        'Truck',
        'Cargo Van',
        'Wagon',
        'Motorcycle',
        'Boat',
        'RV',
        'Aircraft',
    ],
    decodedStyle: '',
    allowEditTrim: true,
    trimList: [],
    engineList: [],
    equipments: {
        standardEquipment: [],
        optionalEquipment: [],
        additionalSpec: [],
    },
    multipleTrim: false,
    userTabs: {
        invUserTabName1: null,
        invUserTabName2: null,
    },
    defaultReconCost: null,
    flooringCompanies: [],
    postVehicleAccounting: false,
    accountingLockDate: null,
    postToDate: null,
    useCustomStock: false,
    stockNumberPrefix: null,
    stockNumberSuffix: null,
    customFields: [],
};
const ACTION_TYPES = {
    ON_CHANGE: 'onChange',
    ON_CHANGE_DATA: 'onChangeData',
    SET_VIN_DATA: 'setVinData',
    ON_CHANGE_TITLE: 'onChangeTitle',
    ON_CHANGE_TAG: 'onChangeTag',
    ON_CHANGE_SECTION: 'onChangeSection',
    SET_DEFAULT_VALUES: 'setDefaultLot',
    SET_PURCHASED_PRICE: 'setPurchasedPrice',
    SET_PACK_OVERRIDE: 'setPackOverride',
    SET_COLORS_DATA: 'setColorsData',
    SET_SETTINGS: 'setSettings',
    SET_TRIM: 'setTrim',
    ON_CLICK_MANUALLY: 'onClickManually',
    SET_PURCHASED_FROM_OPTIONS: 'setPurchasedFromOptions',
    SET_PURCHASING_AGENT_OPTIONS: 'setPurchasingAgentOptions',
    TOGGLE_PURCHASED_CREATION_LOADING: 'togglePurchasedCreationLoading',
    ON_PURCHASED_FROM_CREATION: 'onPurchasedFromCreation',
    ON_PURCHASING_AGENT_CREATION: 'onPurchasingAgentCreation',
    ON_PURCHASING_AGENT_EDIT: 'onPurchasingAgentEdit',
    ON_PURCHASING_AGENT_DELETE: 'onPurchasingAgentDelete',
    SET_LOT_SETTINGS: 'setLotSettings',
};

const reducer = produce((draftState, { type, payload = null }) => {
    switch (type) {
    case ACTION_TYPES.SET_LOT_SETTINGS:
        const {
            defaultReconCost,
            userTabs,
            enableDefaultAds,
            enableDefaultFloorPlanned,
            defaultFlooringCompany,
            postVehicleAccounting,
            accountingLockDate,
            postToDate,
        } = payload;

        const {
            data,
            data: {
                advertisingInfo,
                floorPlanInfo,
            },
        } = draftState;

        draftState.defaultReconCost = defaultReconCost;
        draftState.userTabs = userTabs;
        draftState.postVehicleAccounting = postVehicleAccounting;
        draftState.accountingLockDate = accountingLockDate;
        draftState.postToDate = postToDate;
        draftState.data = {
            ...data,
            advertisingInfo: {
                ...advertisingInfo,
                isPlaceOnline: enableDefaultAds,
            },
            floorPlanInfo: {
                ...floorPlanInfo,
                isFloorPlanned: enableDefaultFloorPlanned,
                flooredBy: defaultFlooringCompany,
                floorPlannedDate: enableDefaultFloorPlanned ? new Date() : null,
            },
        };
        break;
    case ACTION_TYPES.ON_CHANGE:
        draftState[payload.fieldName] = payload.value;
        break;
    case ACTION_TYPES.SET_VIN_DATA:
        draftState.data = {
            ...draftState.data,
            ...payload,
        };
        break;
    case ACTION_TYPES.ON_CHANGE_DATA:
        const isTrim = payload.fieldName === 'trim';
        if (
            isTrim
            && draftState.trimList.length > 0
            && !draftState.trimList.includes(payload.value)
        ) {
            draftState.trimList = [...draftState.trimList, payload.value];
        }

        if (payload.fieldName === 'postToDate') {
            draftState.postToDate = payload.value;
        } else if (['stockNumberPrefix', 'stockNumberSuffix'].includes(payload.fieldName)) {
            draftState[payload.fieldName] = payload.value;
        } else {
            draftState.data[payload.fieldName] = payload.value;
        }

        break;
    case ACTION_TYPES.ON_CLICK_MANUALLY:
        draftState.enterManually = true;
        break;
    case ACTION_TYPES.ON_CHANGE_SECTION:
        const {
            section,
            fieldName,
            value,
        } = payload;

        if (
            section === 'floorPlanInfo'
            && fieldName === 'isFloorPlanned'
            && !value
        ) {
            draftState.data[section].floorPlannedDate = null;
            draftState.data[section].flooredBy = 0;
            draftState.data[section].isBorrowedAmountOverride = false;
            draftState.data[section].borrowedAmount = 0;
        } else if (fieldName === 'isFloorPlanned') {
            const {
                data: {
                    purchasingInfo: {
                        purchasedPrice,
                    },
                },
            } = draftState;

            draftState.data[section].floorPlannedDate = new Date();
            draftState.data[section].borrowedAmount = purchasedPrice;
        }

        draftState.data[section][fieldName] = value;
        break;
    case ACTION_TYPES.SET_PURCHASED_PRICE:
        const {
            data: {
                floorPlanInfo: {
                    isBorrowedAmountOverride,
                },
            },
        } = draftState;

        draftState.data.pricingInfo = payload.pricingInfo;
        draftState.data.purchasingInfo.cost = payload.totalCost;
        draftState.data.purchasingInfo.purchasedPrice = payload.purchasedPrice;
        if (!isBorrowedAmountOverride) draftState.data.floorPlanInfo.borrowedAmount = payload.purchasedPrice;
        break;
    case ACTION_TYPES.ON_CHANGE_TITLE:
        draftState.data.title = false;
        draftState.data.titleNumber = '';
        break;
    case ACTION_TYPES.ON_CHANGE_TAG:
        draftState.data.tag = '';
        draftState.data.tagExpires = null;
        break;
    case ACTION_TYPES.SET_DEFAULT_VALUES:
        draftState.data.lotName = payload.defaultLot;
        draftState.data.purchasingInfo.pack = payload.packCost;
        draftState.useCustomStock = payload.useCustomStock;
        draftState.stockNumberPrefix = payload.stockNumberPrefix;
        draftState.stockNumberSuffix = payload.stockNumberSuffix;
        break;
    case ACTION_TYPES.SET_PACK_OVERRIDE:
        draftState.data.purchasingInfo.pack = payload;
        draftState.data.purchasingInfo.packOverride = false;
        break;
    case ACTION_TYPES.SET_COLORS_DATA:
        draftState.colorsData = payload;
        break;
    case ACTION_TYPES.SET_TRIM:
        draftState.trimList = payload.trimList;
        draftState.multipleTrim = payload.multipleTrim;
        break;
    case ACTION_TYPES.SET_SETTINGS:
        draftState.allowEditTrim = payload.allowEditTrim;
        draftState.trimList = payload.trimList;
        draftState.engineList = payload.engineList;
        draftState.decodedStyle = payload.decodedStyle;
        draftState.equipments = payload.equipments;
        draftState.styleList = payload.styleList;
        break;
    case ACTION_TYPES.TOGGLE_PURCHASED_CREATION_LOADING:
        draftState.purchasedCreationLoading = !draftState.purchasedCreationLoading;
        break;
    case ACTION_TYPES.ON_PURCHASED_FROM_CREATION:
        const { purchasedFromId, name: purchasedFrom } = payload;

        draftState.purchasedFromList.push({
            purchasedFrom,
            purchasedFromId,
        });
        draftState.data.purchasingInfo.purchasedFrom = purchasedFrom;
        draftState.purchasedCreationLoading = false;
        break;
    case ACTION_TYPES.ON_PURCHASING_AGENT_CREATION:
        const { purchasingAgentsId, name: purchasedAgent } = payload;

        draftState.purchasingAgentList.push({
            agent: purchasedAgent,
            purchasingAgentsId,
        });
        draftState.data.purchasingInfo.purchasedAgent = purchasedAgent;
        draftState.purchasedCreationLoading = false;
        break;
    case ACTION_TYPES.ON_PURCHASING_AGENT_EDIT:
        draftState.purchasingAgentList = draftState.purchasingAgentList.map((pa) => {
            if (pa.purchasingAgentsId === payload.id) {
                pa.agent = payload.name;
                return pa;
            }

            return pa;
        });
        draftState.purchasedCreationLoading = false;
        break;
    case ACTION_TYPES.ON_PURCHASING_AGENT_DELETE:
        draftState.purchasingAgentList = draftState.purchasingAgentList.filter((pa) => pa.purchasingAgentsId !== payload.id);
        draftState.purchasedCreationLoading = false;
        break;
    case ACTION_TYPES.SET_PURCHASED_FROM_OPTIONS:
        draftState.purchasedFromList = payload;
        break;
    case ACTION_TYPES.SET_PURCHASING_AGENT_OPTIONS:
        draftState.purchasingAgentList = payload;
        break;
    default:
        break;
    }
});
const calculatePrices = (_purchasedPrice, isSlashedPrice) => {
    const pricingInfo = {
        isSlashedPrice,
        slashedPrice: 0,
        stickerPrice: 0,
        internetPrice: 0,
        minimumPrice: 0,
    };

    return pricingInfo;
};
const getPackCost = (lots, defaultLot) => {
    const packCost = lots.find((item) => item.lotName === defaultLot)?.packCost || 0;

    return packCost;
};
const getSettingData = (data, onlyReconCost = false) => {
    const { INV_USER_TAB_NAME_1, INV_USER_TAB_NAME_2, INV_DEFAULT_MAX_RECON_COST } = InventoryCategory;
    const invUserTabName1 = SettingsHelper.getValueByKey(data, INV_USER_TAB_NAME_1);
    const invUserTabName2 = SettingsHelper.getValueByKey(data, INV_USER_TAB_NAME_2);
    const defaultReconCost = SettingsHelper.getValueByKey(data, INV_DEFAULT_MAX_RECON_COST);

    return onlyReconCost ? {
        defaultReconCost: Number(defaultReconCost || 0),
    } : {
        invUserTabName1,
        invUserTabName2,
    };
};

const showErrorMessage = (errorList) => {
    errorList.forEach((item) => {
        const { error, message: errorMessage } = item;

        if (error) {
            const messageList = StringUtils.isEmpty(errorMessage) ? error?.graphQLErrors : null;

            ModalUtils.errorMessage(messageList, errorMessage);
        }
    });
};

const CreateForm = () => {
    const client = useApolloClient();
    const classes = useStyles();
    const history = useHistory();
    const {
        createFrom,
        createAgent,
        editAgent,
        deleteAgent,
    } = usePurchasingActions();
    const [state, dispatch] = useReducer(reducer, initState);
    const { userInformation } = useContext(UserContext);

    const { data: { year: inputtedYear, vin: inputtedVin } } = state;
    const { isInvalidVINLength, isInvalidVINChars } = useVinValidation(inputtedYear, inputtedVin, state.enterManually);
    const validateRequiredFields = (data) => {
        const {
            year, make, model, style, userTabURL1, userTabURL2,
            postVehicleAccounting, postToDate,
            purchasingInfo: { purchasedFrom, purchasedPrice },
            advertisingInfo: { isReducePrice, reducePrice, every },
            floorPlanInfo: {
                isFloorPlanned,
                floorPlannedDate,
                flooredBy,
                borrowedAmount,
            },
        } = data;
        const invalidFloorPlan = isFloorPlanned
            && (StringUtils.isEmpty(floorPlannedDate) || Number(flooredBy) <= 0 || borrowedAmount <= 0);
        const invalidPurchasedFrom = StringUtils.isEmpty(purchasedFrom);
        const invalidPurchasedPrice = purchasedPrice <= 0;
        const invalidReducePrice = isReducePrice && StringUtils.isEmpty(reducePrice);
        const invalidFrequency = isReducePrice && StringUtils.isEmpty(every);
        const invalidYear = InventoryHelper.hasStockInvalidYear(year);
        const invalidMake = StringUtils.isEmpty(make);
        const invalidModel = StringUtils.isEmpty(model);
        const invalidStyle = StringUtils.isEmpty(style);
        const validURL1 = !StringUtils.isEmpty(userTabURL1) && !InventoryHelper.validateCustomTabURL(userTabURL1);
        const validURL2 = !StringUtils.isEmpty(userTabURL2) && !InventoryHelper.validateCustomTabURL(userTabURL2);
        const invalidPostDate = postVehicleAccounting && StringUtils.isEmpty(postToDate);

        return !(invalidFloorPlan || invalidPurchasedFrom || invalidPurchasedPrice || invalidReducePrice
            || invalidFrequency || invalidYear || invalidMake || invalidModel
            || invalidStyle || isInvalidVINLength || isInvalidVINChars || validURL1 || validURL2 || invalidPostDate);
    };

    const [createEquipment, { loading: savingEquipments }] = useMutation(InventoryMutation.CREATE_EQUIPMENT);
    const [loadVinData, {
        data: vinData, loading: vinLoading, error: vinError,
    }] = useLazyQuery(InventoryQuery.GET_VEHICLE_DATA);
    const {
        data: purchasedFromData, error: purchasedFromError, loading: purchasedFromLoading,
    } = useQuery(SettingsQuery.GET_PURCHASED_FROM, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });
    const {
        data: purchasingAgentData, error: purchasingAgentError, loading: purchasingAgentLoading,
    } = useQuery(SettingsQuery.GET_PURCHASING_AGENT, {
        fetchPolicy: FetchPolicy.NO_CACHE,
    });
    const { EXTERIOR_COLOR, INTERIOR_COLOR, GENERIC_EXTERIOR_COLOR } = InventoryColor;
    const { CAR_FAX, AUTO_CHECK } = VehicleInfoProviders;
    const {
        data: enumsData, error: enumsError, loading: enumsLoading,
    } = useQuery(CatalogQuery.ENUM_VALUES, {
        variables: {
            descriptions: [
                EXTERIOR_COLOR, INTERIOR_COLOR,
                GENERIC_EXTERIOR_COLOR, CAR_FAX, AUTO_CHECK,
            ],
        },
    });

    const {
        data: vendorListResponse,
        loading: loadingVendorList,
        error: vendorListError,
    } = useQuery(VendorQuery.GET_VENDOR_LIST, {
        variables: {
            filter: {
                vendorType: [VendorType.FLOORPLAN],
            },
            sort: {
                field: 'vendorName',
                dir: DataSort.ASC,
            },
        },
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const {
        data: customFieldsResponse,
        loading: loadingCustomFields,
        error: customFieldsError,
    } = useQuery(InventoryQuery.GET_VEHICLE_CUSTOM_FIELDS, {
        fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });

    const saveEquipments = async (stockNumber) => {
        const {
            standardEquipment,
            optionalEquipment,
            additionalSpec,
        } = state.equipments;
        const variables = {
            stockNumber,
            trim: state.data.trim,
            decodedStyle: state.decodedStyle,
            standardEquipment: standardEquipment || [],
            optionalEquipment: optionalEquipment || [],
            additionalSpec: additionalSpec || [],
            isEditing: false,
        };
        const errorMessage = 'There was an error trying to save the equipments';

        try {
            const response = await createEquipment({ variables });

            if (!response?.data?.createEquipment) {
                ModalUtils.errorMessage(null, errorMessage);
            }
        } catch (ex) {
            ModalUtils.errorMessage(null, errorMessage);
        }
    };

    const [saveCustomFields] = useMutation(InventoryMutation.SAVE_VEHICLE_CUSTOM_FIELDS, {
        onError: (errorMessage) => {
            ModalUtils.errorMessage(null, errorMessage);
        },
    });

    const [createVehicle, { loading: creatingVehicle }] = useMutation(InventoryMutation.CREATE_VEHICLE, {
        onCompleted: (response) => {
            if (response) {
                const stockNumber = response.createVehicle;

                // Save customs fields
                const { customFields } = state;
                if (customFields.length > 0) {
                    saveCustomFields({
                        variables: {
                            stockNumber: stockNumber.toString(),
                            input: state.customFields.map((cf) => ({
                                entityMetadataId: cf.entityMetadataId,
                                value: cf.value || '',
                            })),
                        },
                    });
                }

                saveEquipments(stockNumber);
                ModalUtils.successMessage(null, 'Vehicle created successfully!');

                history.push({
                    pathname: `/${modules.INVENTORY}/${stockNumber}`,
                    state: { isNewCar: true },
                });
            }
        },
        onError: (err) => {
            ModalUtils.errorMessage(null, err);
        },
    });

    const errorList = [
        {
            error: vinError,
            message: 'There was an error trying to decoding this vin number.',
        },
        { error: purchasedFromError },
        { error: purchasingAgentError },
        { error: enumsError },
    ];

    const {
        INV_USER_TAB_NAME_1, INV_USER_TAB_NAME_2,
        INV_DEFAULT_MAX_RECON_COST,
        PLACE_ONLINE_ADS_DEFAULT,
        FLOOR_PLANNED_DEFAULT,
        FLOORING_COMPANY_DEFAULT,
    } = InventoryCategory;

    const {
        POST_VEHICLE_ACCOUNTING,
        DATE_LOCK,
    } = LotDefaultskey;

    const calculateInitialPostDate = (accountingLockDate) => {
        const today = new Date();
        const isBefore = DateUtils.isSameOrBefore(today, accountingLockDate);
        return isBefore ? new Date(DateUtils.add(accountingLockDate, 1)) : today;
    };

    useEffect(() => {
        if (customFieldsError) {
            ModalUtils.errorMessage(customFieldsError?.graphQLErrors);
            return;
        }

        if (!loadingCustomFields) {
            const fields = customFieldsResponse?.getCustomFieldsInventory;
            if (fields) {
                dispatch({
                    type: ACTION_TYPES.ON_CHANGE,
                    payload: {
                        fieldName: 'customFields',
                        value: fields,
                    },
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCustomFields, customFieldsError]);

    useEffect(() => {
        const getSettings = async () => {
            try {
                const { data: userTabsData } = await client.query({
                    query: LotQuery.GET_SETTINGS,
                    variables: {
                        category: LotsCategory.INVENTORY,
                        lotName: state.data.lotName,
                        key: [INV_USER_TAB_NAME_1, INV_USER_TAB_NAME_2],
                    },
                    fetchPolicy: FetchPolicy.NETWORK_ONLY,
                });

                const { data: inventorySettings } = await client.query({
                    query: LotQuery.GET_SETTINGS,
                    variables: {
                        category: LotsCategory.INVENTORY,
                        lotName: ALL_LOTS,
                        key: [
                            INV_DEFAULT_MAX_RECON_COST,
                            PLACE_ONLINE_ADS_DEFAULT,
                            FLOOR_PLANNED_DEFAULT,
                            FLOORING_COMPANY_DEFAULT,
                        ],
                    },
                    fetchPolicy: FetchPolicy.NETWORK_ONLY,
                });

                const { data: accountingSettings } = await client.query({
                    query: LotQuery.GET_SETTINGS,
                    variables: {
                        category: LotsCategory.ACCOUNTING,
                        lotName: ALL_LOTS,
                        key: [
                            POST_VEHICLE_ACCOUNTING,
                            DATE_LOCK,
                        ],
                    },
                    fetchPolicy: FetchPolicy.NETWORK_ONLY,
                });

                const inventory = inventorySettings?.getSettings;
                const accounting = accountingSettings?.getSettings;

                const savedLockDate = accounting.find((setting) => setting.key === DATE_LOCK)?.value;
                const accountingLockDate = savedLockDate ? new Date(DateUtils.getOnlyDate(new Date(savedLockDate))) : null;
                const postToDate = accountingLockDate ? calculateInitialPostDate(accountingLockDate) : new Date();
                dispatch({
                    type: ACTION_TYPES.SET_LOT_SETTINGS,
                    payload: {
                        userTabs: getSettingData(userTabsData?.getSettings),
                        defaultReconCost: getSettingData(inventory, true).defaultReconCost,
                        enableDefaultAds: (inventory.find((setting) => setting.key === PLACE_ONLINE_ADS_DEFAULT)?.value ?? 'false') === 'true',
                        enableDefaultFloorPlanned: (inventory.find((setting) => setting.key === FLOOR_PLANNED_DEFAULT)?.value ?? 'true') === 'true',
                        defaultFlooringCompany: Number(inventory.find((setting) => setting.key === FLOORING_COMPANY_DEFAULT)?.value ?? 0),
                        postVehicleAccounting: (accounting.find((setting) => setting.key === POST_VEHICLE_ACCOUNTING)?.value ?? 'false') === 'true',
                        accountingLockDate,
                        postToDate,
                    },
                });
            } catch (error) {
                ModalUtils.errorMessage(null, error);
            }
        };

        if (state.data.lotName) getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.data.lotName]);

    useEffect(() => {
        const options = purchasedFromData?.getPurchasedFrom;
        if (options) {
            dispatch({
                type: ACTION_TYPES.SET_PURCHASED_FROM_OPTIONS,
                payload: options,
            });
        }
    }, [purchasedFromData]);

    useEffect(() => {
        const options = purchasingAgentData?.getPurchasingAgent;
        if (options) {
            dispatch({
                type: ACTION_TYPES.SET_PURCHASING_AGENT_OPTIONS,
                payload: options,
            });
        }
    }, [purchasingAgentData]);

    useEffect(() => {
        showErrorMessage(errorList);
        // eslint-disable-next-line
    }, [
        vinError, purchasedFromError, purchasingAgentError, enumsError,
    ]);

    useEffect(() => {
        if (vendorListError) {
            ModalUtils.errorMessage(vendorListError?.graphQLErrors);
            return;
        }

        if (!loadingVendorList) {
            const vendors = vendorListResponse?.getVendorList;
            if (vendors) {
                dispatch({
                    type: ACTION_TYPES.ON_CHANGE,
                    payload: {
                        fieldName: 'flooringCompanies',
                        value: vendors,
                    },
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingVendorList, vendorListError]);

    const { styleList } = state;
    const addColors = (record = {}) => {
        const interiorColors = [...record.interiorColors];
        const exteriorColors = [...record.exteriorColors];
        const genericColors = [...record.genericColors];
        let { colorsData } = state;

        interiorColors.reverse();
        exteriorColors.reverse();
        genericColors.reverse();
        colorsData = colorsData.filter((item) => !item.fromAPI);

        genericColors.forEach((item) => {
            const { colorName } = item;

            if (!StringUtils.isEmpty(colorName)) {
                colorsData.unshift({
                    type: EXTERIOR_COLOR,
                    description: colorName,
                    fromAPI: true,
                });
                colorsData.unshift({
                    type: INTERIOR_COLOR,
                    description: colorName,
                    fromAPI: true,
                });
            }
        });

        interiorColors.forEach((item) => {
            const { colorName } = item;

            if (!StringUtils.isEmpty(colorName)) {
                colorsData.unshift({
                    type: INTERIOR_COLOR,
                    description: colorName,
                    fromAPI: true,
                });
            }
        });

        exteriorColors.forEach((item) => {
            const { colorName } = item;

            if (!StringUtils.isEmpty(colorName)) {
                colorsData.unshift({
                    type: EXTERIOR_COLOR,
                    description: colorName,
                    fromAPI: true,
                });
            }
        });

        dispatch({
            type: ACTION_TYPES.SET_COLORS_DATA,
            payload: colorsData,
        });
    };
    const handleVINData = (records = {}) => {
        let trimList = records.trim || [];
        let styles = [...styleList];
        const multipleTrim = trimList.length > 1;

        if (!StringUtils.isEmpty(records.bodyStyle) && !styleList.includes(records.bodyStyle)) {
            styles = [records.bodyStyle, ...styles];
        }

        dispatch({
            type: ACTION_TYPES.SET_SETTINGS,
            payload: {
                allowEditTrim: true,
                trimList,
                styleList: styles,
                engineList: records.engines || [],
                decodedStyle: records.decodedStyle,
                equipments: {
                    standardEquipment: records.standardEquipment || [],
                    optionalEquipment: records.optionalEquipment || [],
                    additionalSpec: records.additionalSpec || [],
                },
            },
        });

        if (!isEmpty(records)) {
            addColors(records);
        }

        if (multipleTrim) {
            const { trim } = state.data;

            if (!StringUtils.isEmpty(trim) && !trimList.includes(trim)) {
                trimList = [trim, ...trimList];
            }

            dispatch({
                type: ACTION_TYPES.SET_TRIM,
                payload: {
                    trimList,
                    multipleTrim,
                },
            });
        }
    };
    const keyStore = new KeyStore();
    const WRITE = keyStore.hasPermission(Permission.INVENTORY_VEHICLE_WRITE);

    useEffect(() => {
        if (vinData) {
            const records = InventoryMap.mapVIN(vinData.decodeVin, state.vinNumber);

            dispatch({
                type: ACTION_TYPES.SET_VIN_DATA,
                payload: records,
            });

            handleVINData(vinData.decodeVin);
        }
        // eslint-disable-next-line
    }, [vinData, state.vinNumber]);
    useEffect(() => {
        if (userInformation) {
            const {
                customStockEnabled,
                customStockSettings,
                lots,
                defaultLot,
            } = userInformation;
            const packCost = getPackCost(lots, defaultLot);

            dispatch({
                type: ACTION_TYPES.SET_DEFAULT_VALUES,
                payload: {
                    defaultLot,
                    packCost,
                    useCustomStock: customStockEnabled,
                    ...(customStockEnabled ? {
                        stockNumberPrefix: customStockSettings.find((i) => i.name === InventoryCategory.INVENTORY_CUSTOM_STOCK_PREFIX)?.value,
                        stockNumberSuffix: customStockSettings.find((i) => i.name === InventoryCategory.INVENTORY_CUSTOM_STOCK_SUFFIX)?.value,
                    } : {}),
                },
            });
        }
    }, [userInformation]);
    useEffect(() => {
        const colors = filter(enumsData?.getEnumValues, (item) => item.type.toUpperCase() !== CAR_FAX || item.type.toUpperCase() !== AUTO_CHECK);

        dispatch({
            type: ACTION_TYPES.SET_COLORS_DATA,
            payload: colors,
        });
    }, [enumsData, CAR_FAX, AUTO_CHECK]);

    const requiredFieldsCompleted = validateRequiredFields(state.data);
    const onSave = async () => {
        if (WRITE && requiredFieldsCompleted) {
            try {
                const {
                    data: {
                        floorPlanInfo: {
                            isFloorPlanned,
                            floorPlannedDate,
                            flooredBy,
                            isBorrowedAmountOverride,
                            borrowedAmount,
                        },
                        lienHolderInfo,
                    },
                    postVehicleAccounting,
                    postToDate,
                    useCustomStock,
                    stockNumberPrefix,
                    stockNumberSuffix,
                } = state;

                const { flooringCompanies } = state;
                const selectedFlooringCompany = flooringCompanies.find((company) => company.vendorId === flooredBy);
                const { vendorFee, floorRate = 0 } = selectedFlooringCompany ?? {};

                const input = {
                    ...MapInventoryData.mapCreateUpdateVechicle(state.data),
                    useCustomStock,
                    stockNumberPrefix,
                    stockNumberSuffix,
                    isFloorPlanned,
                    ...(isFloorPlanned ? {
                        flooredBy: selectedFlooringCompany?.vendorName ?? '',
                        floorPlannedDate: floorPlannedDate ? new Date(floorPlannedDate).toISOString() : null,
                        borrowedAmountOverride: isBorrowedAmountOverride ?? false,
                        borrowedAmount: borrowedAmount ?? 0,
                        flooredRate: floorRate,
                        floorplanCost: InventoryHelper.calculateFloorplanCost(
                            vendorFee,
                            borrowedAmount,
                            floorRate,
                            floorPlannedDate,
                        ),
                    } : {}),
                    ...(postVehicleAccounting ? {
                        postToDate,
                    } : {}),
                    ...(Object.keys(lienHolderInfo).length > 0 ? {
                        lienHolder: lienHolderInfo,
                    } : {}),
                };

                createVehicle({
                    variables: {
                        input,
                    },
                });
            } catch (error) {
                ModalUtils.errorMessage(null, error);
            }
        }
    };
    const onClickManually = () => {
        dispatch({
            type: ACTION_TYPES.ON_CLICK_MANUALLY,
        });
    };
    const onChange = (fieldName, value) => {
        dispatch({
            type: ACTION_TYPES.ON_CHANGE,
            payload: { fieldName, value },
        });
    };
    const onCreate = async (field, value) => {
        if (field === 'purchasedFrom') {
            createFrom(
                dispatch,
                ACTION_TYPES.TOGGLE_PURCHASED_CREATION_LOADING,
                ACTION_TYPES.ON_PURCHASED_FROM_CREATION,
                value,
            );
        }
        if (field === 'purchasedAgent') {
            createAgent(
                dispatch,
                ACTION_TYPES.TOGGLE_PURCHASED_CREATION_LOADING,
                ACTION_TYPES.ON_PURCHASING_AGENT_CREATION,
                value,
            );
        }
    };

    const onEditPurchasingAgent = (id, name) => {
        editAgent(
            dispatch,
            ACTION_TYPES.TOGGLE_PURCHASED_CREATION_LOADING,
            ACTION_TYPES.ON_PURCHASING_AGENT_EDIT,
            id,
            name,
        );
    };

    const onDeletePurchasingAgent = (id) => {
        deleteAgent(
            dispatch,
            ACTION_TYPES.TOGGLE_PURCHASED_CREATION_LOADING,
            ACTION_TYPES.ON_PURCHASING_AGENT_DELETE,
            id,
        );
    };

    const decodeVin = () => {
        if (!StringUtils.isEmpty(state.vinNumber)) {
            loadVinData({
                variables: {
                    vin: state.vinNumber,
                    trim: '',
                },
            });
        }
    };
    const onChangeData = (fieldName, value, section = null) => {
        if (fieldName === 'purchasedPrice') {
            const { data: { purchasingInfo, pricingInfo } } = state;
            const totalCost = value + purchasingInfo.repairs;
            const { isSlashedPrice } = pricingInfo;
            const calculate = calculatePrices(value, isSlashedPrice);

            dispatch({
                type: ACTION_TYPES.SET_PURCHASED_PRICE,
                payload: {
                    pricingInfo: calculate,
                    purchasedPrice: value,
                    totalCost,
                },
            });
        } else if (fieldName === 'packOverride' && !value) {
            const { lots } = userInformation;
            const packCost = getPackCost(lots, state.data.lotName);

            dispatch({
                type: ACTION_TYPES.SET_PACK_OVERRIDE,
                payload: packCost,
            });
        } else if (section) {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE_SECTION,
                payload: { fieldName, value, section },
            });
        } else if (fieldName === 'title' && !value) {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE_TITLE,
            });
        } else if (fieldName === 'tag' && StringUtils.isEmpty(value)) {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE_TAG,
            });
        } else if (['stockNumberPrefix', 'stockNumberSuffix'].includes(fieldName)) {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE_DATA,
                payload: { fieldName, value: value?.toUpperCase() },
            });
        } else {
            dispatch({
                type: ACTION_TYPES.ON_CHANGE_DATA,
                payload: { fieldName, value },
            });
        }
    };

    const onChangeCustomField = (fieldName, value) => {
        const clone = cloneDeep(state.customFields);
        const index = clone.findIndex((cf) => cf.entityMetadataId === fieldName);
        if (index >= 0) clone[index].value = value;

        dispatch({
            type: ACTION_TYPES.ON_CHANGE,
            payload: {
                fieldName: 'customFields',
                value: clone,
            },
        });
    };

    const updateCustomFieldOptions = (customField) => {
        const { options, value } = customField;
        const isValueIncluded = options.some(
            (option) => option.option === value,
        );

        const clone = cloneDeep(state.customFields);
        const index = clone.findIndex((cf) => cf.entityMetadataId === customField.entityMetadataId);
        if (index >= 0) {
            clone[index].options = options;
            clone[index].value = isValueIncluded ? value : '';
        }

        dispatch({
            type: ACTION_TYPES.ON_CHANGE,
            payload: {
                fieldName: 'customFields',
                value: clone,
            },
        });
    };

    const vinWasDecoded = !!vinData && !StringUtils.isEmpty(state.data.vin);
    const availableLots = userInformation?.lots || [];
    const loading = purchasedFromLoading || purchasingAgentLoading
        || enumsLoading || state.purchasedCreationLoading;
    const providersOptions = filter(enumsData?.getEnumValues, (item) => item.type.toUpperCase() === CAR_FAX || item.type.toUpperCase() === AUTO_CHECK);

    return (
        <div className={clsx('d-flex-column', classes.main)}>
            <Toolbar
                onSave={onSave}
                onChange={onChangeData}
                requiredFieldsCompleted={requiredFieldsCompleted}
                writePermission={WRITE}
                loading={loading || creatingVehicle || savingEquipments}
                postVehicleAccounting={state.postVehicleAccounting}
                lockedDate={state.accountingLockDate}
                postToDate={state.postToDate}
                vinWasDecoded={vinWasDecoded}
                enterManually={state.enterManually}
            />
            <VinDecoder
                vinNumber={state.vinNumber}
                onChange={onChange}
                decodeVin={decodeVin}
                loading={vinLoading}
                onClickManually={onClickManually}
                vinWasDecoded={vinWasDecoded || state.enterManually}
            />
            <VehicleForm
                vinWasDecoded={vinWasDecoded}
                availableLots={availableLots}
                flooringCompanies={state.flooringCompanies}
                onChange={onChangeData}
                onCreate={onCreate}
                onEdit={onEditPurchasingAgent}
                onDelete={onDeletePurchasingAgent}
                enterManually={state.enterManually}
                data={state.data}
                loading={loading}
                invUserTabName={state.userTabs}
                providersOptions={providersOptions}
                colorsData={state.colorsData}
                engineList={state.engineList}
                styleList={state.styleList}
                allowEditTrim={state.allowEditTrim}
                multipleTrim={state.multipleTrim}
                trimList={state.trimList}
                purchasedFromList={state.purchasedFromList || []}
                purchasingAgentList={state.purchasingAgentList || []}
                defaultReconCost={state.defaultReconCost}
                customStock={{
                    enabled: state.useCustomStock,
                    stockNumberPrefix: state.stockNumberPrefix,
                    stockNumberSuffix: state.stockNumberSuffix,
                }}
                customFields={state.customFields}
                onChangeCustomField={onChangeCustomField}
                updateCustomFieldOptions={updateCustomFieldOptions}
            />
        </div>
    );
};

export default CreateForm;
